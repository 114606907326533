@import '../../../scss/_variables.scss';
.catalogue-start__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .catalogue-start__title {
        height: 10rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 3rem;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-primary;
    }

    .catalogue-start__viewer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        min-height: 300px;

        .catalogue-start__left {
            flex: 0 0 300px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            padding-right: 1rem;
        }

        .catalogue-start__right {
            flex: 1 1 200px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            min-height: 300px;
        }
    }
}
