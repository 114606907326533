@import '../../../../scss/_variables.scss';

._catalogue-product__files {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: $color-background;
    padding: 1rem;

    ._catalogue-product__file {
        width: 30%;
        background-color: $color-elem-background;
        box-shadow: 0 0 0.2rem 0.1rem $color-elem-shadow;
        border-radius: 0.3rem;
        margin: 1rem 0.5rem;
        overflow: hidden;

        a {
            text-decoration: none;
            color: $color-primary;
            font-size: 1.4rem;
        }

        ._catalogue-product__file-preview {
            width: 100%;
            border-bottom: 1px solid $color-grey-4;

            img {
                width: 100%;
                max-height: 300px;
            }
        }

        ._catalogue-product__file-preview-empty {
            width: 100%;
            height: 200px;
            @include ff-icon();
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 5rem;
            color: $color-grey-3;
        }

        ._catalogue-product__file-label {
            padding: 1rem;
        }
    }
}
