@import '../../scss/_variables.scss';

.request-form {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .request-form__row {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        .request-form__label {
            font-size: 1.2rem;
            font-weight: bold;
            color: $color-primary;
            text-transform: uppercase;
        }

        .request-form__error {
            font-size: 1.2rem;
            color: $color-red;
            font-weight: bold;
        }

        input {
            width: 100%;
            border: 1px solid $color-primary;
            height: 3rem;
            padding: 0 1rem;
            border-radius: 0.3rem;
        }
    }

    .request-form__submit {
        background-color: $color-primary;
        border: none;
        border-radius: 0.3rem;
        color: $color-primary-c;
        height: 4rem;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: 500;
    }
}
