@import '../../../../scss/_variables.scss';

._catalogue-product__bindings {
    width: 100%;
}
._catalogue-product__bindings-type {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $color-grey-4;

    ._catalogue-product__bindings-type-element {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        cursor: pointer;

        ._catalogue-product__bindings-type-element-label {
            flex: 1 0 10rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 1rem;
            font-size: 1.4rem;
        }

        ._catalogue-product__bindings-type-element-icon {
            @include ff-icon();
            color: $color-grey-2;
            font-size: 2rem;
            width: 4rem;
            min-height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    ._catalogue-product__category-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: 1px solid $color-grey-4;
        background-color: $color-background-3;

        ._catalogue-product__category-main-element {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            cursor: pointer;

            ._catalogue-product__category-main-element-label {
                flex: 1 0 10rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 1rem 1rem 1rem 3rem;
                font-size: 1.4rem;
            }

            ._catalogue-product__category-main-element-icon {
                @include ff-icon();
                color: $color-grey-2;
                font-size: 2rem;
                width: 4rem;
                min-height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    ._catalogue-product__category-secondary {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: 1px solid $color-grey-4;
        background-color: $color-background-2;

        ._catalogue-product__category-secondary-element {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            cursor: pointer;

            ._catalogue-product__category-secondary-element-label {
                flex: 1 0 10rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 1rem 1rem 1rem 5rem;
                font-size: 1.4rem;
            }

            ._catalogue-product__category-secondary-element-icon {
                @include ff-icon();
                color: $color-grey-2;
                font-size: 2rem;
                width: 4rem;
                min-height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    ._catalogue-product__products {
        padding: 1rem;
        width: 100%;
        border-top: 1px solid $color-grey-4;
        background-color: $color-background;
    }

    ._catalogue-product__product-element {
        width: 100%;
        display: flex;
        background-color: $color-elem-background;
        box-shadow: 0 0 0.2rem 0.1rem $color-elem-shadow;
        border-radius: 0.3rem;
        margin: 0 0 1rem 0;
        overflow: hidden;

        ._catalogue-product__product-image {
            flex: 0 0 10rem;
            border-right: 1px solid $color-grey-4;
        }

        ._catalogue-product__product-details {
            flex: 1 0 10rem;
            padding: 1rem;
            display: flex;
            justify-content: center;
            flex-direction: column;

            ._catalogue-product__product-name {
                width: 100%;
                font-size: 1.4rem;
                color: $color-primary;
                font-weight: 500;
            }

            ._catalogue-product__product-ean {
                width: 100%;
                font-size: 1.4rem;
                color: $color-grey-2;
                font-weight: 500;
            }
        }

        ._catalogue-product__product-buttons {
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
