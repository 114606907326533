@import '../../scss/_variables.scss';

.request-product-list-item__wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    border: 1px solid $color-grey-4;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;

    .request-product-list-item__photo {
        flex: 0 0 30%;
        border-right: 1px solid $color-grey-4;

        .request-product-list-item__photo-img {
            width: 100%;
            max-height: 100px;
        }

        .request-product-list-item__photo-empty {
            width: 100%;
            height: 80px;
            @include ff-icon();
            color: $color-grey-1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
        }
    }

    .request-product-list-item__details {
        flex: 1 0 10rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;

        .request-product-list-item__name {
            font-size: 1.4rem;
            font-weight: bold;
            color: $color-primary;
        }

        .request-product-list-item__ean {
            font-size: 1.4rem;
            font-weight: bold;
            color: $color-grey-2;
        }

        .request-product-list-item__quantity {
            margin-top: 1rem;

            input {
                width: 10rem;
                height: 3rem;
                text-align: center;
                border: 1px solid $color-primary;
                border-radius: 0.3rem;
                background-color: $color-background;
                color: $color-primary;
                font-size: 1.2rem;
            }
        }
    }

    .request-product-list-item__remove {
        flex: 0 0 5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .request-product-list-item__remove-button {
            width: 3rem;
            height: 3rem;
            border-radius: 1.5rem;
            justify-content: center;
            align-items: center;
            display: flex;
            @include ff-icon();
            color: $color-grey-1;
            font-size: 2.2rem;
            cursor: pointer;
            border: 2px solid transparent;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: $color-primary;
                border-color: $color-primary;
            }
        }
    }
}
