._button-light {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    padding: 0 2rem;
    background-color: transparent;
    border: 1px solid $color-primary;
    border-radius: 0.3rem;
    @include ff-title();
    text-transform: uppercase;
    color: $color-primary;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 1.4rem;

    .material-icons {
        font-size: 2.5rem;
        margin: 0 0.5rem;
    }

    &:hover {
        background-color: $color-primary;
        color: $color-primary-c;
    }

    &.small {
        height: 2.4rem;
        padding: 0 1rem;
        font-size: 1.2rem;
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 4rem;
    padding: 0.5rem 2rem;
    background-color: transparent;
    border: 1px solid $color-primary;
    @include ff-title();
    font-weight: bold;
    text-transform: uppercase;
    color: $color-primary;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 1.4rem;
    border-radius: 0.3rem;

    &:hover {
        background-color: $color-primary;
        color: $color-primary-c;
    }

    &.small {
        min-height: 3rem;
        padding: 0 1rem;
        font-size: 1.2rem;
    }
}
