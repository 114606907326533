@import '../../scss/_variables.scss';
.request-modal__container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;

    .request-modal__background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba($color-background, 0.5);
    }

    .request-modal__modal {
        min-width: 320px;
        width: 30%;
        height: 100%;
        position: relative;
        background-color: $color-elem-background;
        box-shadow: -0.2rem 0 0.4rem 0.2rem $color-elem-shadow;
        overflow: hidden;

        .request-modal__wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
        }

        .request-modal__header {
            flex: 0 0 6rem;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $color-grey-4;

            .request-modal__header-close {
                width: 4rem;
                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2.5rem;
                @include ff-icon();
                border-radius: 2rem;
                color: $color-primary;
                border: 2px solid transparent;
                margin: 0 1rem;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    border-color: $color-primary;
                }
            }

            .request-modal__header-title {
                font-weight: bold;
                text-transform: uppercase;
                color: $color-primary;
                font-size: 1.8rem;
            }
        }

        .request-modal__content {
            flex: 1 0 10rem;
            overflow: auto;

            .request-modal__content-no-products {
                width: 100%;
                padding: 3rem 1rem;
                text-align: center;
                font-size: 1.6rem;
            }
        }
    }
}

.test-request-content {
    height: 400px;
}
