@import '../../../scss/_variables.scss';
.category-chooser__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    background-color: $color-elem-background;
    box-shadow: 0 0 0.4rem 0.2rem $color-elem-shadow;
    border-radius: 0.5rem;
    overflow: hidden;

    .category-chooser__parent {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $color-grey-4;

        &:last-child {
            border-bottom: none;
        }

        .category-chooser__parent-element {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            font-weight: 500;

            .category-chooser__parent-element-title {
                flex: 1 0 10rem;
                padding: 1.5rem;
                font-size: 1.4rem;
            }

            .category-chooser__parent-element-icon {
                flex: 0 0 5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                @include ff-icon();
                font-size: 2rem;
                color: $color-grey-2;
            }

            &.is-selected {
                background-color: $color-primary;
                color: $color-primary-c;

                .category-chooser__parent-element-icon {
                    color: $color-primary-c;
                }
            }
        }

        .category-chooser__child-element {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-top: 1px solid $color-grey-4;
            background-color: $color-background;
            cursor: pointer;

            .category-chooser__child-element-title {
                flex: 1 0 10rem;
                padding: 1.5rem;
                font-size: 1.4rem;
            }

            .category-chooser__child-element-icon {
                flex: 0 0 5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                @include ff-icon();
                font-size: 2rem;
                color: $color-grey-2;
            }

            &.is-selected {
                background-color: $color-primary;
                color: $color-primary-c;

                .category-chooser__child-element-icon {
                    color: $color-primary-c;
                }
            }
        }
    }
}
