.content-basic {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
    background-color: $color-elem-background;
    box-shadow: 0 0 0.6rem 0.3rem $color-elem-shadow;
    border-radius: 0.3rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    min-height: 15rem;
    overflow-x: auto;
}

.content-basic__header {
    width: 100%;
    padding: 2rem;
    border-bottom: 1px solid $color-grey-3;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    ._button-light {
        margin-right: 1rem;
    }
}

.content-basic__footer {
    width: 100%;
    padding: 2rem;
    border-top: 1px solid $color-grey-3;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    ._button-light {
        margin-right: 1rem;
    }
}

.content-basic__content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    min-height: 15rem;
    overflow-x: auto;
    padding: 2rem;
}

.nav-secondary {
    width: 100%;
    background-color: $color-elem-background;
    box-shadow: 0 0 0.6rem 0.3rem $color-elem-shadow;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 0.3rem;

    a {
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        text-decoration: none;
        color: $color-primary;
        font-weight: bold;
    }
}


.area {
    width: 100%;
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .area-wrapper {
        width: 1000px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
}

.list {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 2rem;
    width: 100%;
    background-color: $color-elem-background;
    box-shadow: 0 0 0.6rem 0.3rem $color-elem-shadow;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    min-height: 15rem;
    overflow-x: auto;

    .buttons {
        margin-bottom: 2rem;
    }
}

.table-wrapper {
    width: 100%;
    overflow-x: auto;
}

table {
    border-collapse: collapse;
    width: 100%;

    tr {
        td {
            padding: 0.5rem 0.5rem;
            font-size: 1.4rem;
        }
    }
    thead {
        tr {
            background-color: $color-primary;
            color: $color-primary-c;

            td {
                font-size: 1.4rem;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $color-background;
        position: absolute;
        opacity: 0.8;
    }

    .popup-container {
        width: 100%;
        max-width: 500px;
        max-height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        background-color: $color-elem-background;
        box-shadow: 0 0 0.6rem 0.3rem $color-elem-shadow;
    }

    .popup-row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem 2rem;

        &:first-child {
            padding-top: 2rem;
        }

        &:last-child {
            padding-bottom: 2rem;
        }
    }

    h2 {
        padding: 0;
        margin: 0;
        color: $color-primary;
        text-transform: uppercase;
    }

    h3 {
        padding: 0;
        margin: 0;
        color: $color-primary;
        text-transform: uppercase;
    }
}
