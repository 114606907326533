@import '../../../scss/_variables.scss';

.product-list-element__wrapper {
    width: 45%;
    margin: 0 1rem 2rem 1rem;
    background-color: $color-elem-background;
    box-shadow: 0 0 0.4rem 0.2rem $color-elem-shadow;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .product-list-element__photo {
        width: 100%;
        max-height: 300px;
        border-bottom: 1px solid $color-grey-4;

        .product-list-element__photo-img {
            width: 100%;
            max-height: 100%;
        }

        .product-list-element__photo-empty {
            width: 100%;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include ff-icon();
            font-size: 10rem;
            color: $color-grey-3;
        }
    }

    .product-list-element__name {
        padding: 1rem;
        font-size: 1.8rem;
        font-weight: 500;
        color: $color-primary;
    }

    .product-list-element__ean {
        padding: 0 1rem 1rem;
        font-size: 1.8rem;
        font-weight: 500;
        color: $color-grey-2;
    }

    .product-list-element__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        padding: 1rem;

        .product-list-element__button {
            text-decoration: none;
            color: $color-primary;
            display: flex;
            min-height: 3rem;
            background-color: transparent;
            border: 1px solid $color-primary;
            padding: 0.7rem 2rem;
            justify-content: center;
            align-items: center;
            font-size: 1.2em;
            text-transform: uppercase;
            font-weight: bold;
            @include ff-text();
            cursor: pointer;
            transition: all 0.3s ease-out;

            &:hover {
                background-color: $color-primary;
                color: $color-primary-c;
            }

            &:not(:last-child) {
                border-right: none;
            }

            &:first-child {
                border-radius: 0.3rem 0 0 0.3rem;
            }

            &:last-child {
                border-radius: 0 0.3rem 0.3rem 0;

                &:first-child {
                    border-radius: 0.3rem;
                }
            }
        }
    }
}
