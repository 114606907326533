@import '../../../scss/_variables.scss';

._catalogue-product {
    max-width: 600px;
    width: 100%;
    background-color: $color-elem-background;
    box-shadow: 0 0 0.4rem 0.2rem $color-elem-shadow;
    border-radius: 0.5rem;
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ._catalogue-product__photo {
        width: 100%;
        border-bottom: 1px solid $color-grey-4;

        img {
            width: 100%;
        }

        ._catalogue-product__photo-empty {
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include ff-icon();
            font-size: 8rem;
            color: $color-grey-1;
        }
    }

    ._catalogue-product__name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0 2rem 0 2rem;
        font-size: 3rem;
        color: $color-primary;
        font-weight: 500;
    }

    ._catalogue-product__ean {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 1rem 2rem 0 2rem;
        font-size: 3rem;
        color: $color-grey-1;
        font-weight: bold;
    }

    ._catalogue-product__type {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0.5rem 2rem 2rem 2rem;
        font-size: 1.8rem;
        color: $color-grey-1;
        font-weight: bold;
        border-bottom: 1px solid $color-grey-4;
    }

    ._catalogue-product__properties {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1px solid $color-grey-4;
    }

    ._catalogue-product__property-group {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }

    ._catalogue-product__property-group-label {
        width: 100%;
        font-size: 1.8rem;
        font-weight: 500;
        padding: 1rem 2rem;
    }

    ._catalogue-product__property {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.4rem;
        padding: 1rem 2rem;
    }

    ._catalogue-product__property-definition-label {
        flex: 0 0 30%;
        color: $color-grey-2;
        font-weight: 500;
    }

    ._catalogue-product__property-content {
        flex: 1 0 50%;
        color: $color-primary;
        font-weight: 500;
    }

    ._catalogue-product__binded-types {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: stretch;
    }

    ._catalogue-product__binded-viewer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: stretch;
    }

    ._catalogue-product__binded-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem 3rem 1rem 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        position: relative;
        border-bottom: 1px solid $color-grey-4;
        cursor: pointer;

        &::before {
            content: 'expand_more';
            @include ff-icon();
            position: absolute;
            right: 2rem;
            color: $color-grey-2;
        }

        &.expanded {
            &::before {
                content: 'expand_less';
            }
        }
    }

    ._catalogue-product__binded-content {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        background-color: $color-grey-5;
        border-bottom: 1px solid $color-grey-4;
    }

    ._catalogue-product__binded-categories {
        flex: 0 0 40%;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        
        border-right: 1px solid $color-grey-3;
    }

    ._catalogue-product__binded-main-category {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: stretch;

    }

    ._catalogue-product__binded-main-category-label {
        width: 100%;
        padding: 1rem 2rem;
        font-size: 1.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        border-bottom: 1px solid $color-grey-4;
        font-weight: 500;
        cursor: pointer;

        &::before {
            content: 'expand_more';
            @include ff-icon();
            position: absolute;
            right: 2rem;
            color: $color-grey-2;
        }

        &.expanded {
            &::before {
                content: 'expand_less';
            }
        }
    }

    ._catalogue-product__binded-child-category {
        width: 100%;
        padding: 1rem 2rem 1rem 4rem;
        font-size: 1.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        border-bottom: 1px solid $color-grey-4;
        font-weight: 500;
        cursor: pointer;

        &::before {
            content: 'keyboard_arrow_right';
            @include ff-icon();
            position: absolute;
            right: 2rem;
            color: $color-grey-2;
        }

        &.selected {
            background-color: $color-primary;
            color: $color-primary-c;
            &::before {
                content: 'check';
                color: $color-primary-c;
            }
        }
    }

    ._catalogue-product__binded-products {
        display: flex;
        flex: 1 0 50%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding: 1rem;
    }

    ._catalogue-product__binded-product-empty {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        font-weight: 500;
        color: $color-grey-2;
        padding: 2rem 1rem;
    }

    ._catalogue-product__binded-product {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        background-color: $color-elem-background;
        box-shadow: 0 0 0.3rem 0.1rem $color-elem-shadow;
        margin-bottom: 1rem;
    border-radius: 0.3rem;
    }

    ._catalogue-product__binded-product-photo {
        flex: 0 0 100px;
    }

    ._catalogue-product__binded-product-info {
        flex: 1 0 100px;
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: column;
        padding: 0.5rem 1rem;
    }

    ._catalogue-product__binded-product-name {
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0 0 0.5rem;
    }

    ._catalogue-product__binded-product-button {
        display: flex;
        justify-content: flex-end;
    }
}
